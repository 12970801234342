import React from "react"
import { Layout } from "components/pageLayouts"
import image from "../../../static/images/manny.png"

const About = () => {
    return (
        <Layout>
            <div className={"row"}>
                <div className={"col-6"}>
                    <img alt={"manny"} src={image} />
                </div>
                <div className={"text-center col-6"}>
                    <h1>About</h1>
                    <div>
                        Justin Kim is a Frontend Developer based in Los Angeles.
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default About

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
    }
`
